









































import { apiAlipayVersionAdd, apiGetAlipayVersionList } from '@/api/shop'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
interface DataItem {
  appVersion: string;
  createTime: string;
  versionDescription: string;
}
@Component({
  components: {
    LsDialog
  }
})
export default class AddUnit extends Vue {
    $refs!: { lsDialog: any; form: any }
    @Prop() value: any
    @Prop() type: any

    @Watch('value', { deep: true })
    valueChange () {
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    }

    versionList:DataItem[] = []

    closeDialog () {
      this.$refs.lsDialog.close()
    }

    openDialog () {
      this.$refs.lsDialog.open()
    }

    handleSave () {
      this.$refs.form.validate((valid: boolean, object: any) => {
        if (valid) {
          const api = apiAlipayVersionAdd(this.value)
          api.then(() => {
            this.closeDialog()
            this.$emit('refresh')
          })
        } else {
          return false
        }
      })
    }

    created () {
      apiGetAlipayVersionList().then(res => {
        console.log(res)
        this.versionList = res.list
      })
    }

    getVersion (val:any) {
      console.log(val, 'vallll')
        const index = this.versionList.findIndex(item => item.appVersion === val)
      this.value.versionDescription=this.versionList[index].versionDescription
    }
}
