






















































import {
  apiAlipayVersionList
} from '@/api/shop'
import { Component, Prop, Vue } from 'vue-property-decorator'
import lsPagination from '@/components/ls-pagination.vue'
import LsDialog from '@/components/ls-dialog.vue'
import { RequestPaging } from '@/utils/util'
import { apiOtherLists } from '@/api/order'
import lsEdit from './edit.vue'
@Component({
  components: {
    lsPagination,
    lsEdit,
    LsDialog
  }
})
export default class ModulesList extends Vue {
  /** S Data **/
  $refs!: { editOrAdd: any }
  apiAlipayVersionList = apiAlipayVersionList;

  pager: RequestPaging = new RequestPaging();

  searchObj = {
    page_size: 15,
    page_no: 1
  };

  form:any = {
    appVersion: '',
    versionDescription: ''
  };

  type=''
  /** E Data **/

  // 获取商城列表
  getShopLists (): void {
    this.pager.request({
      callback: apiAlipayVersionList,
      params: {
        page_size: 15,
        page_no: 1
      }
    })
  }

  handleAdd () {
    this.form = {
      appVersion: '',
      versionDescription: ''
    }
    this.type = 'add'
    console.log(this.$refs.editOrAdd, 'this.$refs.editOrAdd')
    // this.$refs.editOrAdd.openDialog()
    this.$refs.editOrAdd.openDialog()
  }

  /** S ods **/
  created () {
    this.getShopLists()
  }
}
