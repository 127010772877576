var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-unit"},[_c('ls-dialog',{ref:"lsDialog",attrs:{"async":true,"width":"740px","top":"30vh","title":"新增小程序版本号","confirmButtonText":"保存"},on:{"cancel":_vm.closeDialog,"confirm":_vm.handleSave}},[_c('div',[_c('el-form',{ref:"form",attrs:{"model":_vm.value,"label-width":"120px","size":"small"}},[_c('el-form-item',{attrs:{"label":"版本号","required":"","prop":"appVersion","rules":[
                    {
                        required: true,
                        message: '请选择版本号',
                        trigger: [ 'change']
                    }
                ]}},[_c('el-select',{attrs:{"placeholder":"请选择版本号"},on:{"change":_vm.getVersion},model:{value:(_vm.value.appVersion),callback:function ($$v) {_vm.$set(_vm.value, "appVersion", $$v)},expression:"value.appVersion"}},_vm._l((_vm.versionList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.appVersion,"value":item.appVersion}})}),1)],1),_c('el-form-item',{attrs:{"label":"简介","required":"","prop":"versionDescription","rules":[
                    {
                        required: true,
                        message: '请输入简介',
                        trigger: ['blur', 'change']
                    }
                ]}},[_c('el-input',{staticStyle:{"width":"380px"},attrs:{"placeholder":"请输入简介","maxlength":200,"type":"textarea","rows":5,"show-word-limit":""},model:{value:(_vm.value.versionDescription),callback:function ($$v) {_vm.$set(_vm.value, "versionDescription", $$v)},expression:"value.versionDescription"}})],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }